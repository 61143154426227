<template>
  <div>
    <custom-breadcrumb :customBreadcrumb="customBreadcrumb"></custom-breadcrumb>
    <div class="row">
      <div class="col-lg-6">
        <v-card>
          <v-card-title>
            <div>
              <small
                class="bg-light-success"
                style="
                  border: 1px solid #ddd;
                  padding: 5px 10px;
                  border-radius: 5px;
                "
              >
                Yetkisi olanlar
              </small>
            </div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="authSearch"
              append-icon="search"
              label="Arama"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="authHeaders"
            :items="getStationAuths"
            :search="authSearch"
            class="elevation-1"
            :loading="getTableLoading"
            loading-text="Yükleniyor... Bekleyin"
            style="cursor: pointer"
          >
            <template v-slot:[`item.process`]="{ item }">
              <v-btn
                :disabled="getTableLoading"
                @click="removeAuth(item.user_id)"
                title="Yetki Kaldır"
                class="mx-2 bg-danger"
                fab
                dark
                x-small
              >
                <v-icon dark>fas fa-user-minus</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.id`]="{ index }">
              <span>{{ index + 1 }}</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div class="col-lg-6">
        <v-card>
          <v-card-title>
            <div>
              <small
                class="bg-light-danger"
                style="
                  border: 1px solid #ddd;
                  padding: 5px 10px;
                  border-radius: 5px;
                "
              >
                Yetkisi olmayanlar
              </small>
            </div>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="noAuthSearch"
              append-icon="search"
              label="Arama"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="noAuthHeaders"
            :items="getStationNoAuths"
            :search="noAuthSearch"
            class="elevation-1"
            :loading="getTableLoading"
            loading-text="Yükleniyor... Bekleyin"
            style="cursor: pointer"
          >
            <template v-slot:[`item.process`]="{ item }">
              <v-btn
                :disabled="getTableLoading"
                @click="addAuth(item.user_id)"
                title="Yetki Ver"
                class="mx-2 bg-success"
                fab
                dark
                x-small
              >
                <v-icon dark>fas fa-user-plus</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.id`]="{ index }">
              <span>{{ index + 1 }}</span>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_STATION_AUTHS,
  UPDATE_STATION_AUTH,
  DELETE_STATION_AUTH,
} from "@/core/services/store/stationAuth.module";
import customBreadcrumb from "@/view/custom/breadcrumb";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "stationAuth.index",
  components: {
    customBreadcrumb,
  },
  data() {
    return {
      customBreadcrumb: {
        title: "Güç Santrali Yetkilendirme",
        type: "Liste",
        pathName: null,
      },
      authSearch: "",
      authHeaders: [
        { text: "#", align: "left", value: "id" },
        {
          text: "Yetki Kaldır",
          value: "process",
          align: "left",
          sortable: false,
        },
        { text: "Kullanıcı", value: "user_str" },
      ],
      noAuthSearch: "",
      noAuthHeaders: [
        { text: "#", align: "left", value: "id" },
        { text: "Yetki Ver", value: "process", align: "left", sortable: false },
        { text: "Kullanıcı", value: "user_str" },
      ],
      id: parseInt(this.$route.params.id),
    };
  },
  methods: {
    addAuth(user_id) {
      this.$store.dispatch(UPDATE_STATION_AUTH, {
        id: this.id,
        user_id: user_id,
      });
    },
    removeAuth(user_id) {
      this.$store.dispatch(DELETE_STATION_AUTH, {
        id: this.id,
        user_id: user_id,
      });
    },
  },
  computed: {
    ...mapGetters([
      "breadcrumbs",
      "pageTitle",
      "getStationAuths",
      "getStationNoAuths",
      "getTableLoading",
    ]),
  },
  created() {
    this.$store.dispatch(GET_STATION_AUTHS, this.id);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Santraller", route: "/stations", icon: "flaticon-car" },
      { title: "Yetkilendirme" },
    ]);
  },
};
</script>

<style scoped>
</style>
